
import { defineComponent, onMounted, ref, watch } from "vue";
import QrScanner from "qr-scanner";
import { useRouter } from "vue-router";

export default defineComponent({
  name: "scanner-feature",
  setup() {
    const router = useRouter();

    const video = ref({} as HTMLVideoElement);
    const url = ref("");
    const scanning = ref(false);

    function scan() {
      scanning.value = true;

      navigator.mediaDevices
        .getUserMedia({ video: { facingMode: "environment" } })
        .then(function (stream) {
          video.value.srcObject = stream;

          const qrScanner = new QrScanner(video.value, (result) => {
            url.value = result;
            scanning.value = false;

            qrScanner.stop();
          });

          qrScanner.start();
        })
        .catch(function () {
          console.log("Something went wrong!");
        });
    }

    watch(url, (_) => {
      const u = new URL(url.value);
      console.log(u.pathname);

      if (!u.pathname) return;
      router.push(u.pathname);
    });

    return {
      video,
      url,
      scanning,

      scan,
    };
  },
});
