<template>
  <div>
    <h2>Dostupné ukázky</h2>
    <nav>
      <router-link to="/capacitors">Kondenzátory</router-link>
    </nav>

    <div class="scanner">
      <h2>Oskenovat z učebnice</h2>
      <scanner-feature />
    </div>
  </div>

  <p class="author">
    <b>Marián Trpkoš</b> <br />Střední škola a vyšší odborná škola aplikované
    kybernetiky s.r.o.
  </p>
</template>


<script lang="ts">
import { defineComponent } from "vue";

import ScannerFeature from "../features/Scanner.vue";

export default defineComponent({
  components: { ScannerFeature },
});
</script>



<style lang="scss" scoped>
.capacitors {
  display: grid;
  justify-content: flex-start;
  grid-auto-flow: column;
  column-gap: 24px;
}

.scanner {
  margin-top: 80px;
}

.author {
  position: fixed;
  bottom: 6px;

  left: 50%;
  transform: translateX(-50%);
  
  width: 94%;
}
</style>