<template>
  <div class="scanner" v-if="scanning">
    <video autoplay id="videoElement" ref="video"></video>
  </div>
  <div v-else>
    <!-- <span>Naskenovaná url: {{ url }}</span> -->
    <button @click="scan()">Naskenovat QR kód</button>
  </div>
</template>

<script lang="ts">
import { defineComponent, onMounted, ref, watch } from "vue";
import QrScanner from "qr-scanner";
import { useRouter } from "vue-router";

export default defineComponent({
  name: "scanner-feature",
  setup() {
    const router = useRouter();

    const video = ref({} as HTMLVideoElement);
    const url = ref("");
    const scanning = ref(false);

    function scan() {
      scanning.value = true;

      navigator.mediaDevices
        .getUserMedia({ video: { facingMode: "environment" } })
        .then(function (stream) {
          video.value.srcObject = stream;

          const qrScanner = new QrScanner(video.value, (result) => {
            url.value = result;
            scanning.value = false;

            qrScanner.stop();
          });

          qrScanner.start();
        })
        .catch(function () {
          console.log("Something went wrong!");
        });
    }

    watch(url, (_) => {
      const u = new URL(url.value);
      console.log(u.pathname);

      if (!u.pathname) return;
      router.push(u.pathname);
    });

    return {
      video,
      url,
      scanning,

      scan,
    };
  },
});
</script>

<style lang="scss" scoped>
button {
  padding: 8px 18px;

  background: #ef5f14;
  border-radius: 6px;

  border: none;

  font-size: 18px;
  color: #ffffff;
  font-weight: bold;
  line-height: 27px;

  box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.15);

  font-family: Poppins;

  &:hover {
    cursor: pointer;
    opacity: 0.7;
  }
}

video {
  width: 100%;
  max-width: 600px;
}
</style>
